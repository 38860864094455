import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    window.location.replace("https://chaircircles.com");
  }, []);

  return null;
};

export default HomePage;