import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ButtonGroup,
  ToggleButton,
  InputGroup,
  Row,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";

const LocationModal = ({
  show,
  location,
  action,
  onHide,
  onSave,
  onConnectZoom,
  error,
}) => {
  const [selectedType, setSelectedType] = useState("In-Person");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (location) {
      setSelectedType(location.type);
      setSelectedLabel(location.label);
      setSelectedAddress(location.address);
      setSelectedId(location._id);
    }
  }, [location]);

  const types = [
    { text: "In Person", value: "In-Person" },
    { text: "Virtual", value: "Virtual" },
  ];

  const onSaveButton = async () => {
    setIsProcessing(true);

    const location = {
      type: selectedType === "In-Person" ? "In-Person" : "Virtual",
      label: selectedLabel,
      address: selectedAddress,
      _id: selectedId,
    };

    try {
      await onSave(location);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.currentTarget.value);
    setSelectedAddress("");
    setSelectedLabel("");
  };

  if (action === "Edit") {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {types.find((type) => type.value === selectedType)?.text || ""}{" "}
            Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row xs={1}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ minWidth: "90px" }}>
                Label
              </InputGroup.Text>
              <Form.Control
                value={selectedLabel}
                onChange={(e) => setSelectedLabel(e.currentTarget.value)}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ minWidth: "90px" }}>
                Address
              </InputGroup.Text>
              <Form.Control
                value={selectedAddress}
                onChange={(e) => setSelectedAddress(e.currentTarget.value)}
              />
            </InputGroup>
            {error && (
              <Alert className="mt-2" variant="danger">
                {error}
              </Alert>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="secondary-button pill-button"
            onClick={onHide}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            className="primary-button pill-button"
            onClick={onSaveButton}
            disabled={isProcessing}
          >
            {isProcessing && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
              </>
            )}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{action} Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row xs={1}>
          <ButtonGroup>
            {types.map((type, idx) => (
              <ToggleButton
                key={idx}
                id={`type-${type.value}`}
                type="radio"
                variant="outline-dark"
                name="typeSelection"
                value={type.value}
                checked={selectedType === type.value}
                onChange={handleTypeChange}
                size="sm"
              >
                {type.text}
              </ToggleButton>
            ))}
          </ButtonGroup>

          <div className="vertical-spacer-2" />

          {selectedType === "In-Person" && (
            <>
              <InputGroup className="mb-3">
                <InputGroup.Text style={{ minWidth: "90px" }}>
                  Label
                </InputGroup.Text>
                <Form.Control
                  placeholder="Brooklyn Office"
                  value={selectedLabel}
                  onChange={(e) => setSelectedLabel(e.currentTarget.value)}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text style={{ minWidth: "90px" }}>
                  Address
                </InputGroup.Text>
                <Form.Control
                  placeholder="34 Atlantic Lane, Suite 521, Brooklyn, NY 11235"
                  value={selectedAddress}
                  onChange={(e) => setSelectedAddress(e.currentTarget.value)}
                />
              </InputGroup>
            </>
          )}

          {selectedType === "Virtual" && (
            <>
              {onConnectZoom && (
                <>
                  <div className="d-flex justify-content-center mb-3">
                    <Button className="zoom-button" onClick={onConnectZoom}>
                      Connect Zoom
                    </Button>
                  </div>

                  <div className="text-center mb-3 or-text">or</div>
                </>
              )}

              <InputGroup className="mb-3">
                <InputGroup.Text style={{ minWidth: "90px" }}>
                  Label
                </InputGroup.Text>
                <Form.Control
                  placeholder="Google Meet"
                  value={selectedLabel}
                  onChange={(e) => setSelectedLabel(e.currentTarget.value)}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text style={{ minWidth: "90px" }}>
                  URL
                </InputGroup.Text>
                <Form.Control
                  placeholder="https://meet.google.com/abc-defg-hij"
                  value={selectedAddress}
                  onChange={(e) => setSelectedAddress(e.currentTarget.value)}
                />
              </InputGroup>
            </>
          )}
          {error && (
            <Alert className="mt-2" variant="danger">
              {error}
            </Alert>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="secondary-button pill-button"
          onClick={onHide}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          className="primary-button pill-button"
          onClick={onSaveButton}
          disabled={isProcessing}
        >
          {isProcessing && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
